<template>
  <div ref="container" class="b-flow" :style="{ height: height }"></div>
</template>
<script>
/* eslint-disable */
import "diagram-js/assets/diagram-js.css";

import Diagram from "diagram-js";

import ConnectModule from "diagram-js/lib/features/connect";
import CreateModule from "diagram-js/lib/features/create";

import MoveCanvasModule from "diagram-js/lib/navigation/movecanvas";

import ZoomScrollModule from "diagram-js/lib/navigation/zoomscroll";

/**自定义模块部分 */
import myModelingModule from "./modules/modeling";
import myDraw from "./modules/draw";

import myImport from "./modules/import";

import { nodeToJson, importData } from "./myflow";

export default {
  name: "b-wflow-viewer",
  props: {
    isSimple: Boolean,
    isAutoHeight: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      scale: 1,
      diagram: null,
      viewHeight: 100,

      nodeMap: {},
      minX: 1000,
      minY: 1000,
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.init();
    });
  },
  computed: {
    height() {
      if (this.isAutoHeight) {
        return "100%";
      } else {
        return `${this.viewHeight}px`;
      }
    },
  },
  methods: {
    init() {
      var container = this.$refs.container;

      this.diagram = new Diagram({
        canvas: {
          container: container,
        },
        modules: [
          ConnectModule,
          CreateModule,
          myModelingModule,
          MoveCanvasModule,
          ZoomScrollModule,
          myDraw,
          myImport,
        ],
      });
      var canvas = this.diagram.get("canvas"),
        elementFactory = this.diagram.get("elementFactory");

      // add root
      this.reset();
      var root = elementFactory.createRoot();
      canvas.setRootElement(root);

      this.initEvent();

      this.$emit("init");
    },
    // 绑定事件
    initEvent() {
      const eventBus = this.diagram.get("eventBus");

      // 双击事件
      eventBus.on("element.dblclick", (e) => {
        this.$emit("elementDblclick", e.element.businessObject);
      });
      // 点击事件
      eventBus.on("element.click", (e) => {
        this.$emit("elementClick", e.element.businessObject);
      });
    },
    reset() {
      this.scale = 1;
      this.diagram.get("canvas").zoom("fit-viewport");
    },
    handlerZoom(radio) {
      const newScale = !radio ? 1.0 : this.scale + radio;
      this.diagram.get("canvas").zoom(newScale);
      this.scale = newScale;
    },
    getData() {
      const all = this.diagram.get("elementRegistry").getAll();
      var res = nodeToJson(all);
      return res;
    },
    setData(data, radio) {
      // 遍历节点获取边界坐标
      this.nodeMap = {};
      data.forEach((node) => {
        this.nodeMap[node.id] = node;
        if (node.type != "myline") {
          if (this.minX > node.x) {
            this.minX = node.x;
          }
          if (this.minY > node.y) {
            this.minY = node.y;
          }
        } else {
          node.waypoint.forEach((line) => {
            if (this.minX > line.x) {
              this.minX = line.x;
            }
            if (this.minY > line.y) {
              this.minY = line.y;
            }
          });
          if (node.label) {
            if (this.minX > node.label.x) {
              this.minX = node.label.x;
            }
            if (this.minY > node.label.y) {
              this.minY = node.label.y;
            }
          }
        }
      });

      if (this.isSimple) {
        data = this.makeSimple(data, radio);
      }
      if (!this.isAutoHeight) {
        this.setVeiwHeight(data, radio);
      }

      this.$nextTick(() => {
        this.diagram.clear();
        this.reset();
        importData(data, this.diagram);

        setTimeout(() => {
          if (radio != undefined) {
            this.handlerZoom(radio);
          }
        }, 100);
      });
    },
    updateElemet(data) {
      let myImporter = this.diagram.get("myImporter");
      myImporter.update(data.id, data);
    },

    setVeiwHeight(data, radio) {
      let maxY = 0;
      data.forEach((node) => {
        let y = 0;
        switch (node.type) {
          case "userTask":
          case "subprocess":
          case "scriptTask":
            y = node.y + 77 * (1 + radio);
            break;
          case "myline":
            y = node.y;
            break;
          default:
            y = node.y + 77 * (1 + radio);
            break;
        }
        if (maxY < y) {
          maxY = y;
        }
      });
      this.viewHeight = maxY + 16;
    },
    // 流程图显示做简化处理
    makeSimple(data, radio) {
      const wfData = [];
      // 坐标偏差值
      radio = radio || 0;
      const dX = this.minX - 16 - (51.8 * 10 * radio) / (1 + radio);
      const dY = this.minY - 16 + (3 - 3 * 10 * radio) / (1 + radio);
      console.log(data);
      data.forEach((node) => {
        if (node.label) {
          node.label.x = node.label.x - dX;
          node.label.y = node.label.y - dY;
        }
        if (node.type != "myline") {
          if (node.type != "scriptTask") {
            node.x = node.x - dX;
            node.y = node.y - dY;
            wfData.push(node);
          }
        } else {
          node.waypoint.forEach((line) => {
            line.x = line.x - dX;
            line.y = line.y - dY;
          });
          if (
            this.nodeMap[node.from].type != "scriptTask" &&
            this.nodeMap[node.to].type != "scriptTask"
          ) {
            wfData.push(node);
          }
        }
      });
      return wfData;
    },
  },
};
</script>
<style lang="less">
@import "./index.less";
</style>
